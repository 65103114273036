@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100;200;400;700;900&display=swap');

:root {
  --font-base: "Vazirmatn";

  --primary-color: #edf2f8;
  --secondary-color: #126760;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

*::-webkit-scrollbar {
  display: none;
}
